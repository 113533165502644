<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <a href="/#/login">登录页面</a>
  </div>
</template>

<script>
// @ is an alias to /src

export default {};
</script>
